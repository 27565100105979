import { useEffect, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import loadSp from "../../lib/loadSp";
import { useTranslations } from "next-intl";
import { setSharedOnSocialMedia } from "../../reducers/ActionFormReducer";
import { getPageSlug, getPageType } from "../../reducers/PageReducer";
import { logShareToGA } from "../TrackingScripts/LogGA";
import getWhatsappURL from "../../lib/utils/share";

const Shares = forwardRef((props, ref) => {
  const { buttons, lang } = props;
  const [shareProgressLoaded, setSareProgressLoaded] = useState(false);
  const [shareConsented, setShareConsented] = useState(false);
  const dispatch = useDispatch();
  const t = useTranslations("default");
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !shareProgressLoaded) {
      loadSp(() => {
        SharePop.go();
        setSareProgressLoaded(true);
      });
    }
  }, [shareProgressLoaded]);

  useEffect(() => {
    if (shareConsented) {
      const scrollEl = document.getElementById("donation-section");
      scrollEl.classList.remove("hidden");
      scrollEl.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [shareConsented]);

  useEffect(() => {
    if (shareConsented) {
      dispatch(
        setSharedOnSocialMedia({ opted: true, completed: shareConsented })
      );
    }
  }, [shareConsented, dispatch]);

  const conversionHash = {
    facebook: "fb",
    twitter: "tw",
    email: "em",
  };

  function handleClick(type) {
    logShareToGA({ type, slug, pageType });
    setShareConsented(true);
  }

  function getEmailButton() {
    const a = buttons.filter((button) => {
      return button?.share_type === "email";
    });
    if (a.length) {
      return (
        <>
          <div className="uppercase text-md priFont-semiBold lg:my-6 my-0 mb-3">
            {t("or")}
          </div>
          <div
            key={a[0].id}
            onClick={() => {
              handleClick(a[0].share_type);
            }}
            className={`share_button sp_${a[0].sp_id} sp_${
              conversionHash[a[0].share_type]
            }_large lg:mx-12 mx-0 lg:my-0 my-2 ${lang}`}
          ></div>
        </>
      );
    }
    return null;
  }

  return (
    <>
      <div id="share-options" className="w-full hidden" ref={ref}>
        <>
          <div className="lg:mx-24 mx-6 min-h-screen flex flex-col items-center md:justify-center justify-start pt-24">
            <div className="md:text-3xl text-2xl my-12 text-center">
              {t("followUp_shareCampaign")}
            </div>

            {/* FB twitter whatsapp buttons */}
            <div className="w-full flex lg:justify-center lg:flex-row flex-col flex-wrap items-center justify-around">
              {buttons.map((button) => {
                switch (button.share_type) {
                  case "facebook":
                  case "twitter":
                    return (
                      <div
                        key={button.id}
                        onClick={() => {
                          handleClick(button.share_type);
                        }}
                        className={`share_button sp_${button.sp_id} sp_${
                          conversionHash[button.share_type]
                        }_large lg:mx-8 mx-4 lg:my-0 my-2 ${lang}`}
                      ></div>
                    );
                  case "whatsapp":
                    return (
                      <div
                        key={button.id}
                        onClick={() => {
                          handleClick(button.share_type);
                        }}
                        className={`share_button ${button.share_type} lg:mx-8 mx-4 lg:my-0 my-2`}
                      >
                        <a
                          className="whatsapp_large"
                          href={getWhatsappURL(button)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          WhatsApp
                        </a>
                      </div>
                    );
                  default:
                    return null;
                }
              })}
            </div>

            {/* Email button */}
            <div className="w-full mt-3 flex justify-center items-center flex-col">
              {getEmailButton()}
            </div>
          </div>
        </>
      </div>
    </>
  );
});

Shares.displayName = "Shares";

export default Shares;
