import { useState } from "react";
import { useSelector } from "react-redux";
import analytics from "../../../lib/analytics";
import { getPageType } from "../../../reducers/PageReducer";
import { GA4_SHARE } from "../../TrackingScripts/helpers";
import classNames from "classnames";
import { useTranslations } from "next-intl";

const SharePanel = ({ classes, buttons: shareVariants, slug, children }) => {
  const translate = useTranslations("default");
  const [isCopied, setIsCopied] = useState(false);
  const shareUrl = `${process.env.NEXT_PUBLIC_SITE_DOMAIN}/a/${slug}/`;
  const pageType = useSelector(getPageType);

  const shareButtons = ["facebook", "whatsapp", "twitter", "email"].map(
    (type) => ({
      type,
      shareUrl: `${shareUrl}?source=${type.substring(0, 2)}`,
      variant: shareVariants.find((button) => button.share_type === type),
    })
  );

  const getPopupUrl = (type, variant) => {
    const encodedUrl = encodeURIComponent(shareUrl);
    const shareUrlMap = {
      facebook: variant?.sp_id
        ? `https://share.eko.org/99/${variant.sp_id}/${variant.share_type}`
        : `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      whatsapp: variant?.title
        ? `https://api.whatsapp.com/send?text=${encodeURIComponent(
            variant.title.replace("{LINK}", shareUrl)
          )}`
        : `https://api.whatsapp.com/send?text=${encodedUrl}`,
      twitter: variant?.sp_id
        ? `https://share.eko.org/99/${variant.sp_id}/${variant.share_type}`
        : `https://twitter.com/share?url=${encodedUrl}`,
      email: variant?.sp_id
        ? `https://share.eko.org/99/${variant.sp_id}/${variant.share_type}`
        : `mailto:?subject=${translate("share_ask_title")}&body=${encodedUrl}`,
    };
    return shareUrlMap[type] || "";
  };

  const handleClick = (ev, { type, variant }) => {
    ev.preventDefault();
    trackEvent(type);
    const popupURL = getPopupUrl(type, variant);
    window.open(
      popupURL,
      "_blank",
      "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
    );
  };

  const handleAsyncClick = async (ev, type) => {
    ev.preventDefault();
    trackEvent(type);
    if (type === "clipboard") {
      try {
        const res = await navigator.clipboard?.writeText(shareUrl);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      } catch (err) {
        console.log("Failed to copy URL: ", err);
      }
    } else if (type === "instagram") {
      const shareData = { url: shareUrl };
      if (navigator.share) {
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.error(`Error with native share: ${err.message}`);
        }
      }
    }
  };

  const trackEvent = (type) => {
    analytics.track(GA4_SHARE, {
      content_type: "link",
      method: type,
      action_page_type: pageType,
      slug,
    });
  };

  return (
    <>
      {children}
      <div
        className={classNames(
          "pr-4 py-3 rounded-full flex flex-row flex-wrap mx-3 items-center justify-between bg-black my-6 bg-gradient-to-r from-indigo-100 via-indigo-50 to-indigo-100 shadow-md",
          classes
        )}
      >
        <div className="rounded-xl flex items-center">
          <img
            src="/assets/share-icon.svg"
            width={40}
            height={40}
            alt="share icon"
            className="p-2"
          />
          <span className="text-base font-bold pl-2 lg:block hidden">
            {translate("share_campaign")}
          </span>
        </div>
        {shareButtons.map((share, index) => (
          <button
            id={`share-panel-${share.type}-icon`}
            name={`share-panel-icon-${share.type}`}
            key={index}
            tabIndex={0}
            type="button"
            aria-label={translate(`share_on_${share.type}`)}
            disabled={false}
            aria-disabled={false}
            className="bg-transparent rounded-full bg-white hover:bg-indigo-300 shadow-lg"
            onClick={(ev) => handleClick(ev, share)}
          >
            <img
              src={`/assets/${share.type}.svg`}
              width={40}
              height={40}
              alt={`${share.type} icon`}
              className="p-2"
            />
          </button>
        ))}
        <button
          id="share-panel-clipboard-icon"
          name="share-panel-icon-clipboard"
          tabIndex={0}
          type="button"
          aria-label={translate("clipboard_aria_label")}
          disabled={isCopied}
          aria-disabled={isCopied}
          className="bg-transparent rounded-full bg-white hover:bg-indigo-300 shadow-lg"
          onClick={(ev) => handleAsyncClick(ev, "clipboard")}
        >
          <img
            src={`/assets/${isCopied ? "copy-success" : "clipboard"}.svg`}
            width={40}
            height={40}
            alt="clipboard icon"
            className="p-2"
          />
        </button>
        <button
          id="share-panel-instagram-icon"
          name="share-panel-icon-instagram"
          tabIndex={0}
          type="button"
          aria-label={translate("share_on_instagram")}
          disabled={false}
          aria-disabled={false}
          className="bg-transparent rounded-full bg-white hover:bg-indigo-300 shadow-lg"
          onClick={(ev) => handleAsyncClick(ev, "instagram")}
        >
          <img
            src={`/assets/instagram.svg`}
            width={40}
            height={40}
            alt="instagram icon"
            className="p-2"
          />
        </button>
      </div>
    </>
  );
};

export default SharePanel;
