import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import dynamic from "next/dynamic";
import qs from "qs";

// Components
import KnownUser from "./KnownUser";
import PaymentTypeSelection from "./PaymentTypeSelection";
import Paypal from "./Paypal";
import SecureStorePaymentOption from "./SecureStorePaymentOption";
import MemberConsentMsg from "./MemberConsentMsg";
import DonateButton from "./DonateButton";
import {
  AdditionalPrintMsg,
  CompleteDonationMsg,
  FinePrintMsg,
} from "./DonationPrintMessages";
import PaymentError from "../Helpers/PaymentError";
import ExpressDonation from "./ExpressDonation";
import { importRetry } from "../../Utils";
import processApplePay from "../../../lib/processApplePay";

const BraintreeCardFields = dynamic(() =>
  importRetry(() => import("./BraintreeCardFields"))
);

// Reducers
import {
  getCurrency,
  getCurrentPaymentType,
  getDonationAmount,
  getShowSavedPayments,
  setRecurring,
  getFormValues,
  getStoreInVault,
  getLocalPaymentClient,
  selectBaseFacebookTrackingData,
  SUPPORTED_LOCAL_PAYMENT_METHODS,
  setSavedPaymentMethods,
  getSavedPaymentMethods,
  setShowSavedPayments,
  getMerchantAccountId,
  getGaItem,
} from "../../../reducers/DonationReducer";
import {
  getCountry,
  setScrollToDonateCompleted,
} from "../../../reducers/ActionFormReducer";
import {
  getFollowUpTemplateTitle,
  getPageId,
  getPageTitle,
  getQueryParams,
  getSupportedFollowUpTemplateTitles,
  selectRecurringDefault,
  getPageType,
  getPageSlug,
} from "../../../reducers/PageReducer";
import {
  getMember,
  getMemberId,
  getIsMemberPresent,
  setMember,
} from "../../../reducers/MemberReducer";
import { postTransaction, trackConversions } from "../../../api";
import { logGa4Ecommerce } from "../../TrackingScripts/LogGA";
import { logEventToFacebook } from "../../TrackingScripts/LogPixel";
import { FB_EV_DONATE } from "../../TrackingScripts/helpers";
import { GA4_ADD_PAYMENT_INFO } from "../../TrackingScripts/helpers";
import analytics from "../../../lib/analytics";

import { ProcessLocalPayment } from "../../../lib/processLocalPayment";
import { handleFollowUpPage } from "../../Utils";
import { batchActions } from "../../../lib/redux-utils";
import { handleException } from "../../../lib/handleApiErrorRespose";
import { getAcceptAll } from "../../../reducers/CookiePreferenceReducer";
import { validatePostalCode } from "../../../lib/utils/postalCodeValidator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import CoverTransactionCosts from "./CoverTransactionCost";
import DonationSummary from "./DonationSummary";
import {
  MerchantAccounts,
  SupportedNetworksByMerchant,
} from "@/types/components/payment";

const Payment = (props) => {
  const dispatch = useDispatch();

  const {
    client,
    loading,
    deviceData,
    scrollToDonate,
    threeDSClient,
    applePayClient,
  } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const router = useRouter();

  const currentPaymentType = useSelector(getCurrentPaymentType);
  const showSavedPayments = useSelector(getShowSavedPayments);
  const savedPaymentMethods = useSelector(getSavedPaymentMethods);
  const store_in_vault = useSelector(getStoreInVault);
  const pageId = useSelector(getPageId);
  const pageSlug = useSelector(getPageSlug);
  const pageTitle = useSelector(getPageTitle);
  const currentFollowUpTemplate = useSelector(getFollowUpTemplateTitle);
  const supportedFollowUpTemplateTitles = useSelector(
    getSupportedFollowUpTemplateTitles
  );
  const amount = useSelector(getDonationAmount);
  const localPaymentClient = useSelector(getLocalPaymentClient);
  const currency = useSelector(getCurrency);
  const country = useSelector(getCountry);
  const formValues = useSelector(getFormValues);

  const baseFacebookTrackingData = useSelector(selectBaseFacebookTrackingData);
  const memberId = useSelector(getMemberId);
  const queryParams = useSelector(getQueryParams);
  const recurringDefault = useSelector(selectRecurringDefault);
  const isMemberPresent = useSelector(getIsMemberPresent);
  const savedMemberDetails = useSelector(getMember);
  const isTrackingCookieAccepted = useSelector(getAcceptAll);
  const merchantAccountId = useSelector(getMerchantAccountId);
  const gaItem = useSelector(getGaItem);
  const slug = useSelector(getPageSlug);
  const pageType = useSelector(getPageType);

  const [submitting, setSubmitting] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [braintreeHostedFields, setBraintreeHostedFields] = useState(null);
  const [paypalInstance, setPaypalInstance] = useState(null);
  const [tokenizeError, setTokenizeError] = useState(null);
  const [supportedNetworks, setSupportedNetworks] = useState([]);

  const isCoverTransactionCostVisible = useFeatureIsOn(
    "cover-transaction-cost"
  );
  const redirectToCarouselPage = useFeatureIsOn("carousel");

  const isWeeklyDonation = queryParams.weekly === "true";
  const isDonationSummaryOn = useFeatureIsOn("donation-summary");

  function getDonationType(recurring_choice) {
    if (recurring_choice === "recurring") {
      return recurring_choice;
    } else {
      return "not_recurring";
    }
  }

  function trackGaSuccessfulTransaction(recurring_choice) {
    logGa4Ecommerce({
      amount: getFinalDonationAmount(recurring_choice === "recurring"),
      currency,
      recurring: recurring_choice,
      gaItem,
      pageType,
      slug,
      email: isMemberPresent ? savedMemberDetails.email : formValues.email,
      phone: isMemberPresent
        ? savedMemberDetails.action_phone_number || savedMemberDetails.phone
        : formValues.action_phone_number || formValues.phone,
    });
  }

  async function trackFacebookDonationEvents(donationData) {
    const {
      recurring,
      content_category,
      donation_type,
      postal,
      currency,
      value,
      ...rest
    } = donationData || {};

    const payload = {
      postal,
      currency,
      amount: value,
      country: country.value,
      email: isMemberPresent ? savedMemberDetails.email : formValues.email,
      phone: isMemberPresent
        ? savedMemberDetails.action_phone_number || savedMemberDetails.phone
        : formValues.action_phone_number || formValues.phone,
    };
    logEventToFacebook(FB_EV_DONATE, {
      ...baseFacebookTrackingData,
      ...payload,
    });
    try {
      await trackConversions({
        action: "Donate",
        payload: {
          pathname: `/a/${pageSlug}`,
          pageId,
          pageTitle,
          ...payload,
        },
        isTrackingCookieAccepted,
      });
    } catch (err) {
      return handleException(err);
    }
  }

  function showMonthlyButton() {
    if (SUPPORTED_LOCAL_PAYMENT_METHODS.includes(currentPaymentType))
      return false;
    if (currentPaymentType === "applepay") return false;
    return recurringDefault !== "only_one_off";
  }

  function showOneOffButton() {
    return recurringDefault !== "only_recurring";
  }

  function showStorePaymentOption() {
    if (currentPaymentType == "applepay") return false;
    return !SUPPORTED_LOCAL_PAYMENT_METHODS.includes(currentPaymentType);
  }

  useEffect(() => {
    if (submitting) {
      document.getElementById("payment-processing").classList.remove("hidden");
      document.getElementById("fundraiser-bar").classList.add("hidden");
    } else {
      document.getElementById("payment-processing").classList.add("hidden");
      document.getElementById("fundraiser-bar").classList.remove("hidden");
    }
  }, [submitting]);

  useEffect(() => {
    const merchantAccount = MerchantAccounts[currency];
    const supportedNetworks = SupportedNetworksByMerchant[merchantAccount];
    if (merchantAccount && supportedNetworks) {
      setSupportedNetworks(supportedNetworks);
    }
  }, [currency]);

  // TODO: setDefaultPaymentType

  function getFinalDonationAmount(isRecurring) {
    // Check if the donation frequency selected is weekly
    if (isWeeklyDonation && isRecurring) {
      // Calculate the weekly donation amount by dividing the total amount by 4 & round down to the nearest whole number, ensuring that it is an integer
      const weeklyDonationAmount = Math.floor(amount / 4) || 1;
      // If the calculated weekly donation amount is 0 (due to a very small total amount), ensure that at least a minimum donation amount of 1 is returned
      return weeklyDonationAmount * 4;
    }
    return amount;
  }

  const getPaymentToken = async (isRecurring) => {
    switch (currentPaymentType) {
      case "card":
        return new Promise((resolve, reject) => {
          if (!braintreeHostedFields) return reject();
          braintreeHostedFields.tokenize(
            {
              vault: isRecurring,
            },
            (error, data) => {
              if (error) {
                if (error.code === "HOSTED_FIELDS_FIELDS_EMPTY")
                  setPaymentError(true);
                setTokenizeError(error);
                return reject(error);
              }
              if (threeDSClient) {
                threeDSClient
                  .verifyCard({
                    onLookupComplete: function (data, next) {
                      next();
                    },
                    nonce: data.nonce,
                    bin: data.details.bin,
                    amount: getFinalDonationAmount(isRecurring),
                    challengeRequested: true,
                  })
                  .then(function (response) {
                    if (
                      !response.liabilityShifted &&
                      response.liabilityShiftPossible
                    ) {
                      setPaymentError(true);
                      reject(error);
                    }
                    resolve(response);
                  })
                  .catch(function (err) {
                    console.log(err);
                    reject(err);
                  });
              } else {
                resolve(data);
              }
            }
          );
        });
      case "paypal":
        return new Promise((resolve, reject) => {
          if (!paypalInstance) return reject();

          const options = {
            flow: store_in_vault ? "vault" : "checkout",
            amount: getFinalDonationAmount(isRecurring),
            currency,
          };
          paypalInstance.tokenize(options, (error, payload) => {
            if (error) return reject(error);
            resolve(payload);
          });
        });
      default:
        break;
    }
  };

  const submitGoCardless = async (payload) => {
    const NEXT_PUBLIC_CHAMPAIGN_API_HOST =
      process.env.NEXT_PUBLIC_CHAMPAIGN_API_HOST;
    const stringifiedPayload = qs.stringify(payload);
    const url = `${NEXT_PUBLIC_CHAMPAIGN_API_HOST}/api/go_cardless/pages/${pageId}/start_flow?${stringifiedPayload}`;
    window.open(url);
  };

  const ga4TrackAddPaymentInfo = () => {
    const price = parseFloat(amount).toFixed(2);
    analytics.track(GA4_ADD_PAYMENT_INFO, {
      currency: currency,
      value: price,
      payment_type: currentPaymentType,
      slug,
      action_page_type: pageType,
      items: [
        {
          item_id: gaItem?.id,
          item_name: gaItem?.id,
          item_list_name: gaItem?.listName,
          price,
          quantity: 1,
        },
      ],
    });
  };

  const makePayment = async (recurring_choice) => {
    const isRecurring = recurring_choice === "recurring";
    dispatch(setRecurring(isRecurring));

    try {
      const { phone, action_phone_number, postal, ...restFormValues } =
        formValues || {};

      const userDetails = isMemberPresent
        ? {
            ...savedMemberDetails,
          }
        : {
            ...restFormValues,
            ...(phone &&
              isPossiblePhoneNumber(phone) && {
                phone,
              }),
            ...(action_phone_number &&
              isPossiblePhoneNumber(action_phone_number) && {
                action_phone_number,
              }),
            ...(postal &&
              validatePostalCode(postal, country?.value) && {
                postal,
              }),
          };
      if (country?.value) userDetails.country = country.value;
      const { irclickid, source, ...rest } = queryParams;
      const finalDonationAmount = getFinalDonationAmount(isRecurring);

      const payload = {
        irclickid,
        action_pronto: 1,
        amount: finalDonationAmount,
        currency,
        recurring: isRecurring,
        store_in_vault,
        user: {
          action_pronto: 1,
          ...userDetails,
          ...rest,
        },
        source,
      };

      if (currentPaymentType === "gocardless") {
        submitGoCardless(payload);
        return;
      }

      setSubmitting(true);

      const paymentToken = await (async () => {
        if (currentPaymentType === "applepay") {
          const apToken = await processApplePay({
            client: applePayClient,
            amount: finalDonationAmount,
            currencyCode: currency,
            supportedNetworks,
          });

          return apToken;
        }

        if (SUPPORTED_LOCAL_PAYMENT_METHODS.includes(currentPaymentType)) {
          return await ProcessLocalPayment({
            localPaymentInstance: localPaymentClient,
            data: payload,
            pageId,
            paymentType: currentPaymentType,
          });
        } else {
          return await getPaymentToken(isRecurring);
        }
      })();

      const recaptcha_action = `donate/pronto/${pageId}`;
      const recaptcha_token = await executeRecaptcha(recaptcha_action);

      const updatedPayload = Object.assign(payload, {
        recaptcha_token,
        recaptcha_action,
        payment_method_nonce: paymentToken.nonce,
        device_data: deviceData,
        ...(paymentToken.threeDSecureInfo?.threeDSecureAuthenticationId && {
          authenticationId:
            paymentToken.threeDSecureInfo.threeDSecureAuthenticationId,
          ...(paymentToken.threeDSecureInfo.status != "unsupported_card" &&
            paymentToken.threeDSecureInfo.liabilityShiftPossible &&
            ["GBP", "EUR", "CHF"].indexOf(currency) >= 0 &&
            currentPaymentType != "applepay" && {
              three_d_secure: true,
            }),
        }),
      });

      postTransaction(pageId, updatedPayload).then((resp) => {
        if (resp.success) {
          const { postal } = updatedPayload.user || {};
          const user_id = resp.tracking?.user_id || memberId;
          const donationData = {
            value: finalDonationAmount,
            currency,
            ...(user_id && {
              user_id,
            }),
            recurring: isRecurring,
            content_category: currentPaymentType,
            donation_type: getDonationType(recurring_choice),
            postal,
          };
          const actions = [];
          if (updatedPayload.store_in_vault && resp.payment_method) {
            actions.push(
              setSavedPaymentMethods([
                resp.payment_method,
                ...savedPaymentMethods,
              ])
            );
            actions.push(setShowSavedPayments(true));
          }
          actions.push(setMember({ ...userDetails, id: user_id }));
          trackFacebookDonationEvents(donationData).then(() => {
            console.log("fbp event tracked");
          });
          trackGaSuccessfulTransaction(recurring_choice);
          if (scrollToDonate) {
            actions.push(setScrollToDonateCompleted(true));
          }
          batchActions(dispatch, actions);
          if (redirectToCarouselPage) {
            sessionStorage.setItem("carousel", JSON.stringify(userDetails));
            router.push(`/signed/${pageSlug}`);
          } else {
            handleFollowUpPage(
              resp.follow_up_url,
              currentFollowUpTemplate,
              supportedFollowUpTemplateTitles
            );
          }
        } else if (resp.errors?.length || !resp.success) {
          console.warn("API payment error", JSON.stringify(resp.errors));
          // Check if resp.errors is an array
          if (Array.isArray(resp.errors)) {
            // TODO: When migrating this to the app router, change the api response from prosecco to avoid doing this check here
            if (
              typeof resp.errors[0].message === "string" &&
              resp.errors[0].message?.includes(
                "Payment instrument type is not accepted by this merchant account"
              )
            ) {
              setPaymentError({ code: "91567" });
            } else {
              setPaymentError(resp.errors[0]);
            }
          } else {
            // Handle the case when resp.errors is not an array
            setPaymentError(resp.errors);
          }
          setSubmitting(false);
        }
      });
    } catch (error) {
      console.warn("Unhandled Payment Error", error);
      if (error && error?.code !== "HOSTED_FIELDS_FIELDS_INVALID") {
        setPaymentError(true);
      }

      setSubmitting(false);
    }
  };

  return (
    <div className="py-4 flex flex-col">
      {paymentError && <PaymentError errorResp={paymentError} />}
      <KnownUser loading={loading} />
      {showSavedPayments && !loading ? (
        <ExpressDonation
          setPaymentError={setPaymentError}
          showMonthlyButton={showMonthlyButton()}
          showOneOffButton={showOneOffButton()}
          scrollToDonate={scrollToDonate}
          threeDSClient={threeDSClient}
        />
      ) : (
        <>
          <PaymentTypeSelection loading={loading} />
          <Paypal
            client={client}
            vault={store_in_vault}
            paypalInstance={paypalInstance}
            setPaypalInstance={setPaypalInstance}
          />
          <BraintreeCardFields
            client={client}
            vault={store_in_vault}
            isActive={currentPaymentType === "card"}
            braintreeHostedFields={braintreeHostedFields}
            setBraintreeHostedFields={setBraintreeHostedFields}
            tokenizeError={tokenizeError}
            merchantAccountId={merchantAccountId}
            threeDSClient={threeDSClient}
          />
          {showStorePaymentOption() && <SecureStorePaymentOption />}
          <div className="h-0.5 bg-gray-200 w-full my-2"></div>
          {isCoverTransactionCostVisible && <CoverTransactionCosts />}
          {showMonthlyButton() && (
            <MemberConsentMsg
              duration={isWeeklyDonation ? "weekly" : "monthly"}
            />
          )}
          <div className="mt-4">
            <CompleteDonationMsg />
            {isDonationSummaryOn && (
              <DonationSummary amount={amount} currency={currency} />
            )}
            <AdditionalPrintMsg currentPaymentType={currentPaymentType} />
            <div className="flex flex-col items-center donate-buttons">
              {showOneOffButton() && (
                <DonateButton
                  submitting={submitting}
                  name="one_time"
                  recurring={false}
                  weekly={isWeeklyDonation}
                  applePay={currentPaymentType === "applepay"}
                  disabled={currentPaymentType === null}
                  onClick={() => {
                    ga4TrackAddPaymentInfo();
                    makePayment("one_time");
                  }}
                  theme="primary"
                />
              )}
              {showMonthlyButton() && (
                <DonateButton
                  submitting={submitting}
                  name="recurring"
                  recurring={true}
                  weekly={isWeeklyDonation}
                  disabled={currentPaymentType === null}
                  onClick={() => {
                    ga4TrackAddPaymentInfo();
                    makePayment("recurring");
                  }}
                  theme="primary"
                />
              )}
            </div>
          </div>
        </>
      )}

      <FinePrintMsg weeklyPrint={isWeeklyDonation} />
    </div>
  );
};

export default Payment;
